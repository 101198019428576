import React from "react";
import { Link, StaticQuery } from "gatsby";
import { FiChevronRight } from "react-icons/fi";

import Layout from "../components/layout";
import SEO from "../components/seo";
import SubHeader from "../components/subheader";

const ResourcePage = () => (
  <Layout>
    <SEO title="Resources" />
    <SubHeader
      title="Resources"
      description="Curated list of resources to help you and your business grow through technical innovation"
      link="resources"
    />
    <div className="container py-5">
      <div className="row">
        <div className="col-md-12 col-lg-8 offset-lg-2">
          <ul>
            <StaticQuery
              query={graphql`
                query AllResourcesQuery {
                  allResources(
                    sort: { fields: [time], order: DESC }
                    filter: { href: { ne: null } }
                  ) {
                    nodes {
                      description
                      extended
                      href
                      time(formatString: "dddd, MMMM Do YYYY")
                      fields {
                        slug
                        tags
                      }
                    }
                  }
                }
              `}
              render={data =>
                data.allResources.nodes.map((node, index) => {
                  return (
                    <li key={`r-${index}`}>
                      <Link
                        to={"resources/" + node.fields.slug}
                        className="highlight"
                      >
                        {node.description}
                        <FiChevronRight />
                      </Link>
                      <br />
                      <small className="text-muted">
                        {node.fields.tags.map((tag, i) => (
                          <span key={`r-${index}-${i}`}>
                            {i > 0 && ", "}
                            {tag.toString().toLowerCase()}
                          </span>
                        ))}
                      </small>
                    </li>
                  );
                })
              }
            />
          </ul>
        </div>
      </div>
    </div>
  </Layout>
);

export default ResourcePage;
